<template>
    <div class="goods-order">
        <van-sticky>
            <van-tabs class="my-tabs" v-model="active" @change="changeType">
                <van-tab title="全部订单"></van-tab>
                <van-tab title="待支付"></van-tab>
                <van-tab title="待发货"></van-tab>
                <van-tab title="待收货"></van-tab>
            </van-tabs>
        </van-sticky>

                <!-- 列表 -->
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                        
                <!-- 订单有三个状态 就是底部的按钮不一样 -->
                <div class="order-list">
                    <div class="item" v-for="item in list" :key="item.id">
                        <!-- 下单 1、支付中  2、已付款 3、已发货  8、订单关闭 9、已退款 -->
                        <div class="color" v-show="item.status === 0">等待买家付款</div>
                        <div class="color" v-show="item.status === 1">支付中</div>
                        <div class="color" v-show="item.status === 2">已付款</div>
                        <div class="color" v-show="item.status === 3">已发货</div>
                        <div class="color" v-show="item.status === 8">已关单</div>
                        <div class="color" v-show="item.status ===9">已退款</div>
                        <van-card
                            :num="item.quantity"
                            :price="item.payAmount | price"
                            :desc="'使用余额: ￥'+item.balanceAmount"
                            :title="item.invName"
                            :thumb="item.picUrl ? getImg(item.picUrl) : imgUrl1"
                        />
                        <!-- 待支付 -->
                        <div class="serve" v-show="item.status === 0 || item.status === 1">
                            <span>需付款</span>
                            <span class="money">
                                {{item.payAmount| totalPrice}}
                            </span>
                        </div>
                        <!-- 待发货 -->
                        <div class="serve-list" v-show="item.status === 2">
                            <div>
                                <span>现金付款:</span>
                                <span class="money">
                                    {{item.payAmount | totalPrice}}
                                </span>
                            </div>
                            <div>
                                <span>余额付款:</span>
                                <span class="money">
                                    {{item.balanceAmount | totalPrice}}
                                </span>
                            </div>
                            <div>
                                <span>共付款:</span>
                                <span class="money">
                                    {{item.amount | totalPrice}}
                                </span>
                            </div>
                        </div>
                        <!-- 待收货 -->
                        <div class="serve-list" v-show="item.status === 3">
                            <div>
                                <span>现金付款:</span>
                                <span class="money">
                                    {{item.payAmount | totalPrice}}
                                </span>
                            </div>
                            <div>
                                <span>余额付款:</span>
                                <span class="money">
                                    {{item.balanceAmount | totalPrice}}
                                </span>
                            </div>
                            <div>
                                <span>共付款:</span>
                                <span class="money">
                                    {{item.amount | totalPrice}}
                                </span>
                            </div>
                        </div>
                        <div class="btn" v-show="item.status === 0 || item.status === 1">
                            <van-button type="default" round @click="closeOrder(item)">关闭订单</van-button>
                            <van-button type="primary" round @click="goPay(item)">付款</van-button>
                        </div>
                        <!-- <div class="btn" v-show="item.status === 2">
                            <van-button type="primary" round>确认收货</van-button>
                        </div> -->
                        <!-- <div class="btn" v-show="item.status === 3">
                            <van-button type="primary" round>确认收货</van-button>
                        </div> -->
                    </div>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
        </van-pull-refresh>
        <!-- 暂无数据 -->
        <no-data v-if="showData"/>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'member/bill/shoppingOrder/shoppingOrder-list',
            active: null,
            imgUrl1: require('@/assets/img/horse1.jpg'),
            // orderList: [
            //     {price: '123.10', checked: false, count: 1, type: '1'},
            // ],
            model: {}
        }
    },
    filters: {
        totalPrice(value) {
            let totals = value.toFixed(2)
            return '￥' + totals;
        },
        price(val) {
            return (val-0).toFixed(2);
        }
    
    },
    methods: {
        init() {
            this.initList()
        },
        changeType() {
            this.judgeActive()
        },
        judgeActive() {
            if(this.active === 0){
                this.model = {}
            }
            if(this.active === 1) {
                this.model = {}
                this.$set(this.model, 'statusStart', 0)
                this.$set(this.model, 'statusEnd', 1)
            }
            if(this.active === 2) {
                this.model = {}
                this.$set(this.model, 'status', 2)
            }
            if(this.active === 3) {
                this.model = {}
                this.$set(this.model, 'status', 3)
            }
            this.init()
        },
        closeOrder(data) {
            this.$help.loading('关单中...')
            this.$api.post('member/bill/shoppingOrder/shoppingOrder-close', {id: data.id}).then(res=>{
                if(res.data){
                    this.$help.success('关单成功!')
                    this.init()
                }else{
                    this.$help.error(res.msg)
                }
            })
            .finally(this.$help.loaded)
        },
        goPay(data) {
            let form = {}
            this.$set(form, 'balanceAmount', data.balanceAmount)
            this.$set(form, 'invId', data.invId)
            this.$set(form, 'quantity', data.quantity)
            this.$set(form, 'payAmount', data.payAmount)
            if(data.logisticsFlag === 1){
                this.$set(form, 'phone', data.phone)
                this.$set(form, 'receivingAddress', data.receivingAddress)
            }
            //console.log(form)
            this.$api.post('member/bill/shoppingOrder/shoppingOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
        }
    },
    mounted() {
        this.active = (this.$route.query.type-0)
        // console.log(this.$route.query)
        this.judgeActive()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .goods-order{
        .my-tabs{
            .van-tabs__line{
                width: 80px;
                background-color: $main;
            }
        }
        .order-list{
            //padding-bottom: 50px;
            .item{
                margin: 20px;
                border-radius: 19px;
                box-shadow: 0 0 6px 9px $boxShadow;
                background-color: #fff;
                padding: 0 5px 10px;
                .color{
                    color: $red;
                    padding: 10px 10px 0 0;
                    text-align: right;
                }
                .van-card{
                    border-radius: 19px;
                }
                .serve{
                    text-align: right;
                    padding: 10px 20px;
                    .money{
                        font-size: 14px;
                    }
                }
                .serve-list{
                    display: flex;
                    padding: 10px 20px;
                    justify-content: space-between;
                    align-items: center;
                    .money{
                        font-size: 14px;
                        color: $pink;
                    }
                }
                .btn{
                    padding: 10px 20px;
                    text-align: right;
                    border-radius: 0 0 19px 19px;
                    .van-button{
                        //back
                        height: 35px !important;
                        padding: 0 25px;
                    }
                    .van-button--default{
                        background-color: #fff;
                        color: #666;
                        border-color: #666;
                    }
                    .van-button--primary{
                        background-color: #fff;
                        color: $red;
                        border-color: $red;
                        margin-left: 20px;
                    }
                }

            }
        }
    }

</style>